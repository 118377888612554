import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

var currSlide = 1;
var root = document.querySelector(":root");

window.addEventListener("DOMContentLoaded", function () {
  resizeWindow(this.window.innerWidth, this.window.innerHeight);
});

export function resizeWindow(width, height) {
  let wh = (width + height) / 2;
  if (wh < 700) {
    if (width < height) {
      wh = width;
    } else {
      wh = height;
    }
    root.style.setProperty(
      "--path",
      `path('m 0 100 q 50-30 100-30 t ${wh / 80} 20 ${wh / 80} 0 90-30 150 30')`
    );
    root.style.setProperty(
      "--path-hover",
      `path('m 5 50 q 45-0 45-0 t ${wh / 75} 0 ${wh / 75} 0 45-0 45 0')`
    );
  } else {
    root.style.setProperty(
      "--path",
      `path('m 0 0 q 50-30 100-30 t ${wh / 10} 20 ${wh / 6} 0 100-30 150 30')`
    );
    root.style.setProperty(
      "--path-hover",
      `path('m 5 0 q 45-0 90-0 t ${wh / 9} 0 ${wh / 7} 0 90-0 90 0')`
    );
  }

  console.log(wh);
}

export function nextSlide() {
  const a = "slide" + currSlide;
  const c = "dot" + currSlide;

  if (currSlide >= 7) {
    currSlide = 0;
    var b = "slide" + (currSlide + 1);
    var d = "dot" + (currSlide + 1);
  } else {
    var b = "slide" + (currSlide + 1);
    var d = "dot" + (currSlide + 1);
  }
  document.getElementById(a).style.display = "none";
  document.getElementById(b).style.display = "block";
  document.getElementById(c).style.backgroundColor = "black";
  document.getElementById(d).style.backgroundColor = "#717171";
  currSlide += 1;
}

export function lastSlide() {
  const a = "slide" + currSlide;
  const c = "dot" + currSlide;

  if (currSlide <= 1) {
    currSlide = 8;
    var b = "slide" + (currSlide - 1);
    var d = "dot" + (currSlide - 1);
  } else {
    var b = "slide" + (currSlide - 1);
    var d = "dot" + (currSlide - 1);
  }
  document.getElementById(a).style.display = "none";
  document.getElementById(b).style.display = "block";
  document.getElementById(c).style.backgroundColor = "black";
  document.getElementById(d).style.backgroundColor = "#717171";
  currSlide -= 1;
}

export function changeSlide(n) {
  const a = "slide" + currSlide;
  const b = "slide" + n;
  const c = "dot" + currSlide;
  const d = "dot" + n;

  document.getElementById(a).style.display = "none";
  document.getElementById(b).style.display = "block";
  document.getElementById(c).style.backgroundColor = "black";
  document.getElementById(d).style.backgroundColor = "#717171";

  currSlide = n;
}

function timedSwitch() {}

export function IndexTitleContainer() {
  return (
    <React.Fragment>
      <div className="     " id="home">
        <div className="container__intro">
          <h1 class="header__name">Hi. I'm Andrew Chen</h1>
          <h3 class="sub-header__intro">
            Currently finishing up my Computer Science BS at Michigan State
            University. I am passionate about software and full stack
            delevopment and everything business.
          </h3>
        </div>
        <div className="container__profile-image">
          <img src="media_public/andrew_profile.png" alt="" />
        </div>
        <div className="container__quote">
          <p className="text__quote">
            <i>"dont dwell on the past, learn from it and move on"</i>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export class SideMenu extends Component {
  render() {
    return (
      <div className="container__sidebar-menu">
        <a href="#home" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#home" className="link__sidebar">
              Home
            </a>
          </div>
        </a>
        <a href="#about" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#about" className="link__sidebar">
              About Me
            </a>
          </div>
        </a>
        <a href="#experiences" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#experiences" className="link__sidebar">
              Experiences
            </a>
          </div>
        </a>
        <a href="#projects" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#projects" className="link__sidebar">
              Projects
            </a>
          </div>
        </a>
        <a href="#courses" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#courses" className="link__sidebar">
              Courses
            </a>
          </div>
        </a>
        <a href="#skills" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#skills" className="link__sidebar">
              Skills
            </a>
          </div>
        </a>
        <a href="#certifications" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#certifications" className="link__sidebar">
              Certifications
            </a>
          </div>
        </a>
        <a href="#connect" className="link__sidebar">
          <div className="container__sidebar-items">
            <a href="#connect" className="link__sidebar">
              Connect
            </a>
          </div>
        </a>
      </div>
    );
  }
}

export class IndexAboutMe extends Component {
  constructor() {
    super();
    this.state = {
      WindowWidth: window.innerWidth,
      WindowHeight: window.innerHeight,
    };
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.addEventListener("resize", null);
  }
  handleResize(WindowWidth, event) {
    this.setState({
      WindowWidth: window.innerWidth,
      WindowHeight: window.innerHeight,
    });
    resizeWindow(window.innerWidth, window.innerHeight);
  }

  render() {
    const changeTimelineCourses = (slideNum) => {
      let oldTimelineText = document.getElementById("timelineTextCourses");
      let oldTimelineTitle = document.getElementById("coursesSubheader");
      let timelineTextContainer = document.getElementById(
        "timelineTextContainerCourses"
      );

      oldTimelineText.remove();
      oldTimelineTitle.remove();

      var newTimelineText = document.createElement("p");
      newTimelineText.className = "text__about-me--green";
      newTimelineText.id = "timelineTextCourses";

      var newTimelineTitle = document.createElement("h2");
      newTimelineTitle.className = "subheader__courses";
      newTimelineTitle.id = "coursesSubheader";

      if (slideNum == 0) {
        var overviewLinkText = document.createElement("p");
        var overviewLink = document.createElement("a");
        overviewLinkText.className = "text__about-me--green";
        overviewLink.innerHTML = "https://reg.msu.edu/courses/search.aspx";
        overviewLink.href = "https://reg.msu.edu/courses/search.aspx";
        overviewLink.target = "_blank";
        overviewLink.className = "course__link--overview";
        overviewLinkText.appendChild(overviewLink);
        newTimelineText.innerHTML =
          "All courses are attented at Michigan State University. Some courses may be courses I am currently attending. This section will include a brief overview of each course, what I learned and accomplished in each course and my proficiency in each course. Any further course information can be found at";
        newTimelineText.style.animation = "fade-in 2s";
        newTimelineText.appendChild(overviewLinkText);
        newTimelineTitle.innerHTML = "Course Overview";
      }
      switch (slideNum) {
        case 0:
          var overviewLinkText = document.createElement("p");
          var overviewLink = document.createElement("a");
          overviewLinkText.className = "text__about-me--green";
          overviewLink.innerHTML = "https://reg.msu.edu/courses/search.aspx";
          overviewLink.href = "https://reg.msu.edu/courses/search.aspx";
          overviewLink.target = "_blank";
          overviewLink.className = "course__link--overview";
          overviewLinkText.appendChild(overviewLink);
          newTimelineText.innerHTML =
            "All courses are attented at Michigan State University. Some courses may be courses I am currently attending. This section will include a brief overview of each course, what I learned and accomplished in each course and my proficiency in each course. Any further course information can be found at";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineText.appendChild(overviewLinkText);
          newTimelineTitle.innerHTML = "Course Overview";
          break;
        case 1:
          newTimelineText.innerHTML =
            "Grade: 4.0 <br/> <br/> Assembly language programming and interfacing to high level languages, boolean algebra and digital logics, and combinational and sequential circuits. Learned how to program and debug C and Assembly. Also learned the interface between the hardware and software of modern computing systems. Worked with circuits and logic gates to create systems. Became very proficient in both programing and debugging C and Assembly, mildly proficient with the circuits and logic gate.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML =
            "Computer Organization and Architecture (CSE 320)";
          break;
        case 2:
          newTimelineText.innerHTML =
            "Grade: 3.5 <br/> <br/> Gained a strong theoretical and conceptual understanding of common data structures and algorithms, as well as how to apply them within larger programming projects. Learned many different topics with algorithms and data structures including many ADTs and data structures, asymptotic and algorithm analysts, recursion and graph algorithms. Very proficient on all algorithms and their complexity, also got pretty good at dynamic programming. Learned many greedy, graph, and general algorithms. Ten total projects on algorithms and data structure, averaging near 100% on all projects. ";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML =
            "Algorithms and Data Structures (CSE 331)";
          break;
        case 3:
          newTimelineText.innerHTML =
            "Grade: 4.0 <br/> <br/> Taught in C++, we developed object oriented software and projects as a team and as individuals. Got to work with a team on large projects and learn to set up sprint meetings in order to strengthen collaboration skills. Created data models on projects and learned to apply them to solutions of programming problems. Became very proficient and learning the C++ very quickly. Created two class projects in C++ including the Angry Sparty physics oriented game and a keyframe movie maker that allowed users to create a stop motion movie.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML =
            "Object oriented Software Design (CSE 335)";
          break;
        case 4:
          newTimelineText.innerHTML =
            "Grade: Currently Attending <br/> <br/> Applied current technologies used to develop software on a project. Learned both the theoretical and practical aspects of software engineering. Went through project planning, requirements engineering, design strategies, software architectures, model driven developments, testing and prototyping. Appointed project manager for group and lead team to design, develop, test and prototype deliverable.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML = "Software Engineering (CSE 435)";
          break;
        case 5:
          newTimelineText.innerHTML =
            "Grade: 4.0 <br/> <br/> Learned many advanced algorithms with a focus on optimization of the time and space complexity of the designed solution. Worked on many projects and assignments with strict time and space complexities requiring highly optimized solutions using advanced algorithms to solve. Focusing on algorithm analysis, design, implementation, and optimization for a broad range of problem categories including techniques to recognize and cope with c problems. I became very proficient with algorithms, optimizing time and space complexities, while also gaining more insight and practice with dynamic programming.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML = "Algorithm Engineering (CSE 431)";
          break;
        case 6:
          newTimelineText.innerHTML =
            "Grade: Currently Attending <br/> <br/> Learned to create web applications and deployment of web applications. Created front end web design using CSS, HTML, Jinja and JavaScript, while also learning to create backend and databases using Python Flask and NoSQL Databases. Working with docker containers and other web related applications. Created frontend and backend personal resume pages. Very proficient in all web development languages and softwares. ";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML =
            "Web Application Architecture and Development (CSE 477)";
          break;
        case 7:
          newTimelineText.innerHTML =
            "Grade: 4.0 <br/> <br/> Propositional and first order logic. Equivalence and methods of proof. Basics of counting. Set operations, relations, functions. Grammars and finite state automata. Discrete probability. Applications to computer science and engineering. Excelled in the course and on each individual assignment.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML =
            "Discrete Structures in Computer Science (CSE 260)";
          break;
        case 8:
          newTimelineText.innerHTML =
            "Grade: 3.5 <br/> <br/>Probability models and random variables. Estimation, confidence intervals, tests of hypotheses, simple linear regression. Used Pandas, statistics, numpy and many other python libraries in order to calculate a multitude of different statistical models and calculations. ";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML =
            "Probability and Statistics for Engineering (STT 351)";
          break;
      }

      timelineTextContainer.appendChild(newTimelineTitle);
      timelineTextContainer.appendChild(newTimelineText);
    };
    const changeTimelineExperiences = (slideNum) => {
      let oldTimelineText = document.getElementById("timelineText");
      let oldTimelineTitle = document.getElementById("experiencesSubheader");
      let timelineTextContainer = document.getElementById(
        "timelineTextContainer"
      );

      oldTimelineText.remove();
      oldTimelineTitle.remove();

      var newTimelineText = document.createElement("p");
      newTimelineText.className = "text__about-me--white";
      newTimelineText.id = "timelineText";

      var newTimelineTitle = document.createElement("h2");
      newTimelineTitle.className = "subheader__experiences";
      newTimelineTitle.id = "experiencesSubheader";

      switch (slideNum) {
        case 0:
          newTimelineText.innerHTML =
            "Extensive knowledge and experiences in Python, C++, HTML, JavaScript, CSS, React, and business development through personal projects and other experiences. Actively looking for more opportunities to expand my skills and improve myself and others.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML = "Overview";
          break;
        case 1:
          newTimelineText.innerHTML =
            "Currently working for Comerica Banks as a Enterprise Architecture intern. Working with a team and individually to develop applications to help automate or simplify processes around the company. Using Postman and other API services to build and integrate APIs into applications. Also moderating and reporting on backend databases.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML = "Comerica Bank";
          break;
        case 2:
          newTimelineText.innerHTML =
            "Online marketing and technology support with devices. Helped fix technology and rewire systems, marketing products and advertising. Fixed and debuged different hardware and software problems on many different technologies";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML = "Ztron";
          break;
        case 3:
          newTimelineText.innerHTML =
            "Founder of a start up beverage company. Currenlty in the process of financing for our first production run through investor talks and a pilot run. Funded over 120k through investors and ourselves. Experienced the steps it takes to manage a full business and the responsibilities of filing taxes and financing our spendings and bankroll. Worked with large companies and spoke with other CEOs to learn and develop our product.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML = "Swift";
          break;
        case 4:
          newTimelineText.innerHTML =
            "Tutoring throughout college in both computer science and business related topics. This includes courses of that include object oriented software development, algorithmic engineering, data structures, and other related or introductory courses. I choose to help tutor and aid other students not only to brush up on some languages that I may not use as often anymore, but to experience working with others through a task and debugging code. I also love being able to help and teach people who may be less skilled at a specific topic";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML = "Tutoring";
          break;
        case 5:
          newTimelineText.innerHTML =
            "Assigned project manager due to expertise and experience shown throughout class and work. Managed a small team of 5, assigning tasks, due dates and maintaining updated progress on each individual task weekly through schedules meetings. Maintained and managed all deiverable submissions while aiding with tasks. Interviewed and analyzed customer requirements for project, creating high level diagrams and rapid prototypes. Created a Software Requirement Specification document.";
          newTimelineText.style.animation = "fade-in 2s";
          newTimelineTitle.innerHTML =
            "Project Manager for Software Engineering";
          break;
      }

      timelineTextContainer.appendChild(newTimelineTitle);
      timelineTextContainer.appendChild(newTimelineText);
    };
    return (
      <section className="section__index-about" id="indexAboutSection">
        <div className="container__index-about-body" id="about">
          <div className="container__index-text-field--fixed">
            <h1 className="header__about-me--white">Who am I?</h1>
            <p className="text__about-me--white">
              Hi, my name is Andrew Chen. I study Computer Science at Michigan
              State University. Currently I am set to graduate in 2024/25. The
              summer of 2021, right after highschool graduation I decided to
              pursue my first ever coding project. I took my passion for the
              stock market and my drive to learn some coding and created a stock
              screening API. Having never coded before, it was a challenge at
              the time. Never did I expect that to have lead me to my passion of
              software, full stack web development, and product management.
            </p>
          </div>
        </div>
        <div className="container__index-about-body">
          <div className="container__index-text-field--fixed">
            <h1 className="header__about-me--green">More about me</h1>
            <p className="text__about-me--green">
              In my free time, I enjoy working on whatever projects I can wrap
              my hands around. Late summer of 2022, I created my first fully
              capable website using HTML and CSS for the front end and
              JavaScript, Python and Google Firebase for the backend. I toyed
              with many different ideas of what I could turn this shell of a
              website into. From online porfolios to a sports betting algorithm,
              it has been through many different iterations. However, now it
              remains just a shell of a website with some of its features intact
              as I continue to pursue other projects and opportunities. I am an
              extremely hard worker who will put in all my effort to solve
              anything. Whether that requires the help of others or learning on
              my own, I am willing and able to work both indiviually and as a
              team.
            </p>
          </div>
        </div>
        <div className="container__index-about-body" id="experiences">
          <div
            className="container__index-text-field--fixed"
            id="timelineTextContainer"
          >
            <h1 className="header__about-me--white">Experiences</h1>
            <h2 className="subheader__experiences" id="experiencesSubheader">
              Overview
            </h2>
            <p className="text__about-me--white" id="timelineText">
              Extensive knowledge and experiences in Python, C++, HTML,
              JavaScript, CSS, React, and business development through personal
              projects and other experiences. Actively looking for more
              opportunities to expand my skills and improve myself and others.
            </p>
          </div>

          <div className="container__timeline">
            <div
              onClick={() => changeTimelineExperiences(0)}
              className="btn link__timeline"
            >
              Overview
            </div>
            <br />
            <div
              onClick={() => changeTimelineExperiences(1)}
              className="btn link__timeline"
            >
              Comerica Bank
            </div>
            <br />
            <div
              onClick={() => changeTimelineExperiences(2)}
              className="btn link__timeline"
            >
              Ztron
            </div>
            <br />
            <div
              onClick={() => changeTimelineExperiences(3)}
              className="btn link__timeline"
            >
              Swift
            </div>
            <br />
            <div
              onClick={() => changeTimelineExperiences(4)}
              className="btn link__timeline"
            >
              Tutoring
            </div>
            <br />
            <div
              onClick={() => changeTimelineExperiences(5)}
              className="btn link__timeline"
            >
              Project Manager
            </div>
          </div>
        </div>
        <div className="container__index-about-body" id="projects">
          <div className="container__index-text-field--fixed">
            <h1 className="header__about-me--green">
              Personal And Class Projects
            </h1>
            <p className="text__about-me--green">
              Here are some of the projects I have worked on over the past
              couple years. (click on the slides for more info)
            </p>
            <section className="index-bar-container">
              <div
                class="slideshow-container"
                onMouseOver={timedSwitch}
                onLoad={() => changeSlide(1)}
              >
                <div id="slide1" class="mySlides slide1 fade">
                  <a href="https://bajamas.org" target="_blank">
                    <img className="slide-img" src={"./media_public/1.png"} />
                  </a>
                  <a href="https://github.com/achen1076/da" target="_blank">
                    <div class="text__slide">github</div>
                  </a>
                </div>
                <div id="slide2" class="mySlides slide2 fade">
                  <a
                    href="https://github.com/achen1076/portfolio"
                    target="_blank"
                  >
                    <img className="slide-img" src={"./media_public/2.png"} />
                  </a>
                  <div class="text__slide"></div>
                </div>
                <div id="slide3" class="mySlides slide3 fade">
                  <a
                    href="https://gitlab.msu.edu/common-grackle/project1/-/tree/master"
                    target="_blank"
                  >
                    <img className="slide-img" src={"./media_public/3.png"} />
                  </a>
                  <div class="text__slide"></div>
                </div>
                <div id="slide4" class="mySlides slide3 fade">
                  <img className="slide-img" src={"./media_public/4.png"} />
                  <div class="text__slide"></div>
                </div>
                <div id="slide5" class="mySlides slide3 fade">
                  <img className="slide-img" src={"./media_public/5.png"} />
                  <div class="text__slide"></div>
                </div>
                <div id="slide6" class="mySlides slide3 fade">
                  <img className="slide-img" src={"./media_public/6.png"} />
                  <div class="text__slide"></div>
                </div>
                <div id="slide7" class="mySlides slide3 fade">
                  <img className="slide-img" src={"./media_public/7.png"} />
                  <div class="text__slide"></div>
                </div>
                <a class="prev" onClick={lastSlide}>
                  &#10094;
                </a>
                <a class="next" onClick={nextSlide}>
                  &#10095;
                </a>
              </div>
              <br />
              <div class="dots">
                <span
                  id="dot1"
                  class="dot dot1"
                  onClick={() => changeSlide(1)}
                ></span>
                <span
                  id="dot2"
                  class="dot"
                  onClick={() => changeSlide(2)}
                ></span>
                <span
                  id="dot3"
                  class="dot"
                  onClick={() => changeSlide(3)}
                ></span>
                <span
                  id="dot4"
                  class="dot"
                  onClick={() => changeSlide(4)}
                ></span>
                <span
                  id="dot5"
                  class="dot"
                  onClick={() => changeSlide(5)}
                ></span>
                <span
                  id="dot6"
                  class="dot"
                  onClick={() => changeSlide(6)}
                ></span>
                <span
                  id="dot7"
                  class="dot"
                  onClick={() => changeSlide(7)}
                ></span>
              </div>
            </section>
          </div>
        </div>
        <div className="container__index-about-body">
          <div className="container__index-text-field--fixed">
            <h1 className="header__about-me--white">Work Projects </h1>
            <p className="text__about-me--white">
              Here are some of the projects I have worked on over the past
              couple months for work. (no images or code can be shown for
              internal privacy reasons)
            </p>
            <div className="work-project__container">
              <img
                className="work-project__image"
                src="./media_public/1_work.png"
              />
              <img
                className="work-project__image"
                src="./media_public/2_work.png"
              />
              <img
                className="work-project__image"
                src="./media_public/3_work.png"
              />
              <img
                className="work-project__image"
                src="./media_public/4_work.png"
              />
              <img
                className="work-project__image"
                src="./media_public/5_work.png"
              />
            </div>
          </div>
        </div>
        <div className="container__index-about-body" id="courses">
          <div
            className="container__index-text-field--fixed"
            id="timelineTextContainerCourses"
          >
            <h1 className="header__about-me--green">Courses</h1>
            <h2 className="subheader__courses" id="coursesSubheader">
              Courses Overview
            </h2>
            <p className="text__about-me--green" id="timelineTextCourses">
              All courses are attented at Michigan State University. Some
              courses may be courses I am currently attending. This section will
              include a brief overview of each course, what I learned and
              accomplished in each course and my proficiency in each course. Any
              further course information can be found at
              <p className="text__about-me--green">
                <a
                  href="https://reg.msu.edu/courses/search.aspx"
                  target="_blank"
                  className="course__link--overview"
                >
                  https://reg.msu.edu/courses/search.aspx
                </a>
              </p>
            </p>
          </div>
          <div className="container__timeline">
            <div
              onClick={() => changeTimelineCourses(0)}
              className="btn link__timeline--green link__timeline--header"
            >
              <p className="text__about-me--green-large">Courses Overview</p>
            </div>
            <div
              onClick={() => changeTimelineCourses(1)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Computer Organization and Architecture
              </p>
            </div>
            <div
              onClick={() => changeTimelineCourses(2)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Algorithms and Data Structures
              </p>
            </div>
            <div
              onClick={() => changeTimelineCourses(3)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Object-oriented Software Design
              </p>
            </div>
            <div
              onClick={() => changeTimelineCourses(4)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Software Engineering
              </p>
            </div>
            <div
              onClick={() => changeTimelineCourses(5)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Algorithm Engineering
              </p>
            </div>
            <div
              onClick={() => changeTimelineCourses(6)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Web Application Architecture and Development
              </p>
            </div>
            <div
              onClick={() => changeTimelineCourses(7)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Discrete Structures in Computer Science
              </p>
            </div>
            <div
              onClick={() => changeTimelineCourses(8)}
              className="btn link__timeline--green"
            >
              <p className="text__about-me--green-smaller">
                Probability and Statistics for Engineering
              </p>
            </div>
          </div>
        </div>
        <div className="container__index-about-body" id="skills">
          <div className="container__index-text-field--fixed">
            <h1 className="header__about-me--white ">Skills</h1>
            <div className="container__skills">
              <div className="subcontainer__skills">
                <p className="subheader__skills">Coding</p>
                <div className="grid__skills-rating">
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Python
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">C++</dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      React
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      HTML
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">CSS</dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">C</dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      JavaScript
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Java
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">SQL</dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Flask
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="subcontainer__skills">
                <p className="subheader__skills">Others</p>
                <div className="grid__skills-rating">
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      UI/UX
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>

                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Google Firebase
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Microsoft Excel
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Terraform
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Microsoft PowerApps
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Microsoft PowerBI
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Business Management
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Figma
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      AWS Cloud
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                  <dl className="description-list__coding-skills-list">
                    <dt className="description-term__coding-skill-item">
                      Docker
                    </dt>
                    <dd className="description-definition__coding-skill-rating">
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star black-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                      <span class="fa fa-star gray-star"></span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <p className="text__skill-footer">
              *Relative to someone with similar amount of experience and age
            </p>
          </div>
        </div>
        <div className="container__index-about-body" id="certifications">
          <div className="container__index-text-field--fixed">
            <h1 className="header__about-me--green">
              Certifications (coming soon)
            </h1>
            <p className="text__about-me--green">
              Currently working on AWS Cloud Practicioner Certification
            </p>
          </div>
        </div>
        <div className="container__index-about-body" id="connect">
          <div className="container__index-text-field--fixed">
            <p className="text__about-me--white">Connect with me!</p>
            <ul className="list__connect">
              <li className="list-item__connect">
                <a
                  href="https://www.linkedin.com/in/andrew-chen-641444208/"
                  target="_blank"
                >
                  <img src="/icons/linkedin.png" width="80vw" height="70vw" />
                </a>
              </li>
              <li className="list-item__connect">
                <a href="mailto: achen1076@gmail.com">
                  <img
                    src="/icons/gmail_icon.png"
                    width="80vw"
                    height="70vw"
                    margin-top="100px"
                  />
                </a>
              </li>
            </ul>
            <p className="text__footer">
              This website is created by Andrew Chen on Visual Studio Code.
              Built with React, HTML, JavaScript and CSS completely by hand.
            </p>
          </div>
        </div>
      </section>
    );
  }
}
